import React, {useRef} from 'react';

import CallToAction from './components/callToAction.jsx';

import ButtonPrimary from './components/buttons/buttonPrimary.jsx';
import H1 from './components/headings/h1.jsx'
import H2 from './components/headings/h2.jsx'
import H3 from './components/headings/h3.jsx'
import Description from './components/texts/description.jsx'
import Body from './components/texts/body.jsx'
import useScrollToRefSection from './hooks/useScrollToRefSection.jsx';

export default function Developers(){

    const sectionRefs = {
        sftp: useRef()
    }

    useScrollToRefSection(sectionRefs);

    return <>
    <div className='container mx-auto px-4 md:px-0 mt-16 md:mt-24'>         
         <div id='api' className='mb-16 md:mb-24 md:w-2/3'>
            <H1>Developer-centric</H1>
            
            <Description>Integrate digital offers in your loyalty platform and other applications with the BYBE API. Utilizing our platform saves you time and energy without the hassle of jumping hoops through regulation.</Description>    
            
            <ButtonPrimary url={'https://docs.bybe.io/'} gaEventCategory={'developers-page-click'} gaEventAction={'click-to-view-api-docs'} newWindow text="View API documentation" mobileCenter _blank />
        </div>
    </div>
    
    <div className='bg-[#f3f4f6]'>
        <div className='container mx-auto px-4 md:px-0 pt-16 md:pt-24'>
            <H2>Getting offers</H2>
            <Description twoThirdsDesktop>Pull live digital rebate offers directly to your app, allowing you to customize the user experience.</Description>    
            <div className='mb-16 md:mb-24 xl:w-3/4'>
                <img src='../images/screenshots/bybe-api/1-get-offer.jpg'></img> 
            </div>

            <H2>Clipping offers</H2>
            <Description twoThirdsDesktop>When offers are saved in your app or website, we record the data within our dashboard for automatic tracking.</Description>    
            <div className='mb-16 md:mb-24 xl:w-3/4'>
                <img src='../images/screenshots/bybe-api/2-clip.jpg'></img> 
            </div>

            <H2>Sending transactions</H2>
            <Description twoThirdsDesktop>Whether the product is purchased online, in-store, or drive-up/pickup, share the transactions through our API and we handle the rest.</Description>    
            <div className='mb-16 md:mb-24 xl:w-3/4'>
                <img src='../images/screenshots/bybe-api/3-post-to-rd.jpg'></img> 
            </div>

            <H2>Delivering redemptions</H2>
            <Description twoThirdsDesktop>Once we receive transactions, we validate the transaction against offer requirements. Then we send an email to the consumer enabling them to cash out via Paypal, Venmo, or a Visa prepcaid card. </Description>    
            <div className='mb-16 md:mb-24 xl:w-3/4'>
                <img src='../images/screenshots/bybe-api/4-payout-flow.jpg'></img> 
            </div>
            
            <div className='pb-16 md:pb-24'>
                <ButtonPrimary url={'https://docs.bybe.io/'} gaEventCategory={'developers-page-click'} gaEventAction={'click-to-view-api-docs'} newWindow text="View API documentation" center _blank />    
            </div>
            
        </div>
    </div>

    <div className='container mx-auto px-4 md:px-0 mt-16 md:mt-24'>  
        <div ref={sectionRefs['sftp']} className='mb-16 md:mb-24'>
            <H1>SFTP integration</H1>
            <Description>Receive consumer data points through a routine a secure file transfer protocol</Description>
            <H2>Requirements</H2>
            <Body>SFTP Hostname: Unique per remote client <br />
                SFTP Port: 22 <br />
                Authentication: User/Password <br /> <br />

                Additional considerations: Remote clients will also be whitelisted by<br />
                IP address before being allowed to connect. <br /><br />

                File format: CSV
            </Body>

            <H2>SFTP integration into the BYBE API for Retail Partner’s loyalty programs (apps)</H2>
            <H3>Redemptions</H3>
            <Body>
                Redemptions to be sent at least once per hour using write-enabled SFTP account<br />
                - SFTP filename: /redemptions/redemptions_[timestamp].csv<br /><br />
                Timestamp will be in ISO 8601 format when the file was generated:<br />
                - YYYY-MM-DDThh:mm:ss.sssZ
            </Body>
            <H3>CSV field mapping</H3>
            <Description>Each line item has an individual row.</Description>
            <div className="grid grid-cols-4 space-4 divide-y" style={{"overflowWrap": "break-word"}}>
                <div className='p-3 bg-black text-white'>Field</div>
                <div className='p-3 bg-black text-white'>Optional</div>
                <div className='p-3 bg-black text-white'>Type</div>
                <div className='p-3 bg-black text-white'>Description</div>

                <div className='p-3'>purchase_date</div>
                <div className='p-3'>false</div>
                <div className='p-3'>timestamp</div>
                <div className='p-3'>When the purchased occured. Format: ISO 8601</div>

                <div className='p-3'>purchased_product_id</div>
                <div className='p-3'>false</div>
                <div className='p-3'>string</div>
                <div className='p-3'>Product identifier (UPC) that is included in the purchase (transaction_id).</div>

                <div className='p-3'>consumer_id</div>
                <div className='p-3'>false</div>
                <div className='p-3'>string</div>
                <div className='p-3'>The consumer identifier (loyalty ID) for this redemption.</div>

                <div className='p-3'>consumer_email</div>
                <div className='p-3'>true</div>
                <div className='p-3'>string</div>
                <div className='p-3'>The consumer’s disbursement email address.</div>

                <div className='p-3'>store_identifier</div>
                <div className='p-3'>false</div>
                <div className='p-3'>string</div>
                <div className='p-3'>The store where the offer was redeemed. Unique identifier generated by client for the resource.</div>

                <div className='p-3'>transaction_id</div>
                <div className='p-3'>false</div>
                <div className='p-3'>string</div>
                <div className='p-3'>Unique transaction or receipt identifier.</div>

                <div className='p-3'>units</div>
                <div className='p-3'>false</div>
                <div className='p-3'>integer</div>
                <div className='p-3'>Quantity of purchased_product_id purchased.</div>

                <div className='p-3'>price_cents</div>
                <div className='p-3'>true</div>
                <div className='p-3'>integer</div>
                <div className='p-3'>Price of purchased_product_id in USD cents.</div>

                <div className='p-3'>details</div>
                <div className='p-3'>true</div>
                <div className='p-3'>string</div>
                <div className='p-3'>Product name of purchased_product_id</div>
            </div>
        </div>
    </div>

    <CallToAction title={'Interested in becoming a partner?'} 
        primaryButtonText={"Connect"}
        primaryButtonURL={'mailto:BYBE.DEMO@bybeapp.com'} 
    />
</>;
}