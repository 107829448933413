import React, {useState, useEffect, useRef} from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson';

import usStatesGeometry from './assets/usStatesGeometry.json';

export default function UsStatesMap({id, data, dataKey, tooltipKeys}) {
  	const chartRef = useRef({});
  	const chart = chartRef.current;
	const path = d3.geoPath();

	const width = 975;
	const height = 610;
	const padding = { top: 25, right: 25, bottom: 25, left: 25 };

	const [minDatum, setMinDatum] = useState();
	const [maxDatum, setMaxDatum] = useState();
	const [indexedStates, setIndexedStates] = useState({});

	useEffect( () => {
        createStateMap();
    }, [] );

    useEffect( () => {
        chart.svg.remove();
        createStateMap();
    } );

	useEffect(() => {
		if (data.length) {
			setMinDatum(data.reduce((minDatum, datum) => datum[dataKey] < minDatum[dataKey] ? datum : minDatum));
			setMaxDatum(data.reduce((maxDatum, datum) => datum[dataKey] > maxDatum[dataKey] ? datum : maxDatum));

			setIndexedStates(data.reduce((indexedStates, datum) => {
				indexedStates[datum.state] = datum;
				return indexedStates;
			}, {}));
		}
	}, [data]);

	function stateColor(datum) {
		if (datum && minDatum && maxDatum) {
			const scale = d3.scaleLinear()
				.range(['#CCe', '#6438ff'])
				.domain([minDatum[dataKey], maxDatum[dataKey]]);

			return scale(datum[dataKey]);
		} else return '#ddd';
    }

	function handleStateMouseover(event, statePathDatum) {
		const state = indexedStates[statePathDatum.properties.name];

        d3.select(this).attr('fill-opacity', '0.8');

		if (state) {
			chart.tooltip.html(tooltipHtml(state))
				.style('left', event.pageX + 15 + 'px')
				.style('top', event.pageY - 55 + 'px')
				.style('color', () => stateColor(state))
				.style('display', 'block')
				.transition().duration(50)
					.style('opacity', 1);
		}
    }

    function handleStateMouseout(event, statePathDatum) {
        d3.select(this).attr('fill-opacity', '1');

        chart.tooltip.transition().duration(50)
            .style('opacity', 0)
            .transition()
                .style('display', 'none');
    }

	function createStateMap() {
        chart.svg = d3.select(`#us-state-map-${id}`)
			.append('svg')
			.attr("viewBox", [0, 0, width + padding.left + padding.right, height + padding.top + padding.bottom]);

		chart.svg.append("g")
			.attr('transform', `translate(${padding.left}, ${padding.top})`)
			.selectAll("path")
			.data(topojson.feature(usStatesGeometry, usStatesGeometry.objects.states).features).enter()
				.append("path")
				.attr("d", path)
				.attr("fill", d => stateColor(indexedStates[d.properties.name]))
				.attr("fill-opacity", "1")
				.on('mousemove', handleStateMouseover)
                .on('mouseout', handleStateMouseout);

		// Efficiently creates stroke of inter-state borders with topojson.mesh
		chart.svg.append("path")
			.datum(topojson.mesh(usStatesGeometry, usStatesGeometry.objects.states, (a, b) => a !== b))
				.attr('transform', `translate(${padding.left}, ${padding.top})`)
				.attr("fill", "none")
				.attr("stroke", "white")
				.attr("stroke-linejoin", "round")
				.attr("pointer-events", "none")
				.attr("d", path);

        chart.tooltip = d3.select('.d3-us-states-map-tooltip')
            .style('opacity', 0)
            .style('background-color', 'transparent')
            .style('position', 'absolute')
            .style('display', 'none')
            .attr('fill', 'white');
    }

    function tooltipHtml(datum) {
        let string = `<div class="c-graph-tip"><h4 class="c-graph-tip__header"><span>${datum.state}</span></h4>`;

		string += `<ul class="c-graph-tip__list || u-unlist u-space-sections-tiny">`

		tooltipKeys.forEach( key => {
			string += `<li>${key.display}: ${datum[key.key]}</li>`
		} );

		string += '</ul>';
        string += '</div>';

        return string;
    }

	return <div className="canvas" id={ `us-state-map-${id}` }></div>;
}
