import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Provider} from "./context.jsx";
import Home from "./Home.jsx";
import Products from "./Products.jsx";
import ForBrands from "./ForBrands.jsx";
import ForRetailers from "./ForRetailers.jsx";
import Company from "./Company.jsx";
import Pricing from "./Pricing.jsx";
import Developers from "./Developers.jsx";
import TermsAndConditions from "./TermsAndConditions.jsx";
import PrivacyPolicy from "./PrivacyPolicy.jsx";

import ScrollToTop from './scrollToTop.js';

import Header from './components/header.jsx';
import Footer from './components/footer.jsx';

function App() {

  return <Provider>
    <div className='relative flex justify-between items-center align-middle w-full px-4 py-2 bg-gradient-to-r from-[#365174] to-[#344161]'>
      <div className='flex w-full justify-around sm:justify-start items-center py-1 space-x-0
        sm:space-x-3 md:space-x-4 lg:space-x-8'>
        <div className='text-lg font-bold text-[#fffffe] tracking-normal z-10 whitespace-nowrap word-spacing-0
          sm:text-xl sm:tracking-wide sm:word-spacing-sm
          md:text-xl md:tracking-wider md:word-spacing
          lg:text-3xl lg:tracking-widest'>
          THE PERFECT PAIRING:&nbsp;
          <div className='font-normal text-medium tracking-normal whitespace-normal word-spacing-0
            sm:text-lg sm:tracking-normal
            md:text-lg md:tracking-wide md:inline
            lg:text-3xl lg:tracking-wider'>
            Bybe Finds a New Home with Swiftly
          </div>
        </div>

        <button onClick={() => window.open('https://www.swiftly.com/news/swiftly-acquires-bybe-powering-the-future-of-alcohol-promotions-in-retail')}
          className='bg-white text-[#3a5b80] p-1 px-3 text-sm font-bold z-10 whitespace-nowrap border-2 border-[#bac9dc]'>
          READ MORE HERE
        </button>
      </div>

      <div className='absolute hidden sm:flex space-x-3 right-1.5/5 h-full justify-center w-24 overflow-hidden items-center z-0'>
        <div class="w-2 h-32 bg-[#b0c4d9] -rotate-[30deg]"></div>
        <div class="w-2 h-32 bg-[#b0c4d9] -rotate-[30deg]"></div>
      </div>

      <img src='/images/Swiflty_Logo_White.png' alt='Swiftly Logo'
      className='w-40 z-10 hidden sm:block object-contain cursor-pointer md:w-52'
        onClick={() => window.open('https://www.swiftly.com/')} />

    </div>
        <Header />

        <ScrollToTop>
          <Switch>
            <Route exact path="/" render={() => <Home />} />
            <Route exact path="/products" render={() => <Products />} />
            <Route exact path="/for-brands" render={() => <ForBrands />} />
            <Route exact path="/for-retailers" render={ () => <ForRetailers />} />
            <Route exact path="/company" render={ () => <Company />} />
            <Route exact path="/pricing" render={ () => <Pricing />} />
            <Route exact path="/developers" render={ () => <Developers />} />
            <Route exact path="/terms-and-conditions.html" render={ () => <TermsAndConditions />} />
            <Route exact path="/privacy-policy.html" render={ () => <PrivacyPolicy />} />
          </Switch>
        </ScrollToTop>

        <Footer />
      </Provider>;
}

export default App;
